<template>
  <div class="card rounded-4" style="height: 110px">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 me-3">
          <b-img :src="require(`@/assets/images/dashboard/${image}`)"
                 class="avatar-md" rounded="circle" fluid />
        </div>
        <div class="flex-grow-1 overflow-hidden">
          <div class="font-size-26 mb-1">
            <span class="fw-bold">{{ val }}</span>
            <span v-if="!$_utils.isEmpty(valUnit)" class="ms-1 text-black-50 font-size-14">{{ valUnit }}</span>
            <span v-if="!$_utils.isEmpty(subVal)" class="ms-1 fw-bold">{{ subVal }}</span>
            <span v-if="!$_utils.isEmpty(subValUnit)" class="ms-1 text-black-50 font-size-14">{{ subValUnit }}</span>
          </div>
          <p class="mb-0 font-size-16 text-truncate text-muted">{{ title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: 'dashboard1.png'
    },
    val: String,
    valUnit: String,
    subVal: String,
    subValUnit: String,
    title: String
  }
}
</script>
